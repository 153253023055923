import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setAlertMessage } from "./alert";
import {
  updateClientTeam as updateClientTeamApi,
  updateClientTeamWallet as updateClientTeamWalletApi
} from "src/api/clientTeam";

interface ClientTeamState {
  loading: boolean;
  error: string;
}

const initialState: ClientTeamState = {
  loading: false,
  error: ""
};

export const updateClientTeam = createAsyncThunk(
  "clientTeam/updateTeam",
  async (
    clientTeamData: {
      _id: string;
      name: string;
      memberId: string;
      margin: number;
    },
    thunkAPI
  ) => {
    try {
      const response = await updateClientTeamApi(clientTeamData);
      thunkAPI.dispatch(
        setAlertMessage({
          message: "The client team margin was updated successfully.",
          severity: "success"
        })
      );
      return response;
    } catch (error) {
      thunkAPI.dispatch(
        setAlertMessage({
          message:
            "There was an error when trying to update the client team's margin. Please try again later.",
          severity: "error"
        })
      );
      throw error;
    }
  }
);

export const updateClientTeamWallet = createAsyncThunk(
  "clientTeam/updateTeamWallet",
  async (
    walletData: {
      clientTeamId: string;
      amount: number;
      reference: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await updateClientTeamWalletApi(walletData);
      thunkAPI.dispatch(
        setAlertMessage({
          message: "The client team wallet was credited successfully!",
          severity: "success"
        })
      );
      return response;
    } catch (error) {
      thunkAPI.dispatch(
        setAlertMessage({
          message:
            "There was an error when trying to credit the client team's wallet. Please try again later.",
          severity: "error"
        })
      );
    }
  }
);

const slice = createSlice({
  name: "clientTeam",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // update client team
      .addCase(updateClientTeam.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateClientTeam.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateClientTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Unknown error occurred";
      })
      // update team wallet
      .addCase(updateClientTeamWallet.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateClientTeamWallet.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateClientTeamWallet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const reducer = slice.reducer;
