import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  IGetClientRequest,
  IUpdateAccountStatusRequest,
  accountStatusUpdate,
  getClients
} from "src/api/client";
import { setAlertMessage } from "./alert";
import { Client } from "src/models/client";
import { RootState } from "src/store";
import { AccountTypeEnum } from "src/models/user";
import { ClientTeam } from "src/models/clientTeam";

export enum SliceStatusEnum {
  IDLE = "IDLE",
  LOADING = "LOADING",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED"
}

interface ClientState {
  docs: Client[];
  totalDocs: number;
  page: number;
  clientTeam: ClientTeam;
  clientTeamMemberId: string;
  openClientTeamMarginModal: boolean;
  openClientTeamCreditWalletModal: boolean;
  status: SliceStatusEnum;
  loading: boolean;
  error: string;
}

const initialState: ClientState = {
  docs: [],
  totalDocs: 0,
  page: 1,
  clientTeam: {} as ClientTeam,
  clientTeamMemberId: "",
  openClientTeamMarginModal: false,
  openClientTeamCreditWalletModal: false,
  status: SliceStatusEnum.IDLE,
  loading: false,
  error: ""
};

interface GetClientOutput {
  docs: Client[];
  totalDocs: number;
}
export const fetchClients = createAsyncThunk<
  GetClientOutput,
  IGetClientRequest
>("clients/fetch", async ({ page, limit, sortBy, order }, thunkAPI) => {
  try {
    const response = await getClients({
      accountState: undefined,
      accountType: AccountTypeEnum.CLIENT,
      showDetail: true,
      showTeam: true,
      showWallet: true,
      count: false,
      page,
      limit,
      sortBy,
      order
    });
    return {
      docs: response.docs,
      totalDocs: response.totalDocs
    };
  } catch (error) {
    thunkAPI.dispatch(
      setAlertMessage({
        message: "Something went wrong. Please refresh or try again later.",
        severity: "error"
      })
    );
    return thunkAPI.rejectWithValue(error.message || "An error occurred");
  }
});

export const updateClientRecord = createAsyncThunk<
  Client | Client[],
  IUpdateAccountStatusRequest
>(
  "client/updateRecord",
  async (clientRecord: IUpdateAccountStatusRequest, thunkAPI) => {
    try {
      const response = await accountStatusUpdate(clientRecord);
      return response;
    } catch (error) {
      let errorMessage: string;
      clientRecord?.option === "Multiple"
        ? (errorMessage =
            "An error occurred with the bulk update. Please refresh the page and try again.")
        : (errorMessage = "There was an error when trying to update");
      thunkAPI.dispatch(
        setAlertMessage({
          message: errorMessage,
          severity: "error"
        })
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const slice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSelectedClientTeam: (state, action: PayloadAction<ClientTeam>) => {
      state.clientTeam = action.payload;
    },
    setSelectedClientTeamMemberId: (state, action: PayloadAction<string>) => {
      state.clientTeamMemberId = action.payload;
    },
    setOpenClientTeamMarginModal: (state, action: PayloadAction<boolean>) => {
      state.openClientTeamMarginModal = action.payload;
    },
    setOpenClientTeamCreditWalletModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openClientTeamCreditWalletModal = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // get clients
      .addCase(fetchClients.pending, (state) => {
        state.loading = true;
        state.status = SliceStatusEnum.LOADING;
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.loading = false;
        state.status = SliceStatusEnum.SUCCEEDED;
        state.docs = action.payload.docs;
        state.totalDocs = action.payload.totalDocs;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.loading = false;
        state.status = SliceStatusEnum.FAILED;
        state.docs = [];
        state.error = action.error.message;
      })
      // update clients
      .addCase(updateClientRecord.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateClientRecord.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateClientRecord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const reducer = slice.reducer;
export const {
  setPage,
  setSelectedClientTeam,
  setSelectedClientTeamMemberId,
  setOpenClientTeamMarginModal,
  setOpenClientTeamCreditWalletModal
} = slice.actions;
export const selectClients = (state: RootState) => state.client.docs;
export const selectTotalDocs = (state: RootState) => state.client.totalDocs;
export const selectClientStatus = (state: RootState) => state.client.loading;
export const selectPage = (state: RootState) => state.client.page;
export const selectClientTeam = (state: RootState) => state.client.clientTeam;
export const selectClientTeamMemberId = (state: RootState) =>
  state.client.clientTeamMemberId;
export const selectOpenTeamMarginModal = (state: RootState) =>
  state.client.openClientTeamMarginModal;
export const selectOpenClientTeamCreditWalletModal = (state: RootState) =>
  state.client.openClientTeamCreditWalletModal;
