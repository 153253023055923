import axios from "axios";
import { WYSPR_WEBAPI_HOST, Response } from "../index";
import { AccountStatusEnum, Role, RoleType } from "src/models/user";
import { Client } from "src/models/client";
import randomId from "src/utils/randomId";
import { AccountTypeEnum } from "../user";
import { GridRowId, GridSelectionModel } from "@mui/x-data-grid";

export interface IGetClientRequest {
  accountType?: AccountTypeEnum;
  accountState?: string;
  showDetail?: boolean;
  showTeam?: boolean;
  showWallet?: boolean;
  count?: boolean;
  page: number;
  limit: number;
  sortBy?: string;
  order?: string;
  queryOptions?
}
interface UpdateClientRequest {
  _id: string;
  role: RoleType;
  mobileNumber: string;
}

export interface IUpdateAccountStatusRequest {
  userId: GridSelectionModel | string | GridRowId;
  accountState: AccountStatusEnum;
  option?: string
}

interface ClientResponse {
  message: {
    clientData: Client;
  };
}

interface UserResponse {
  message: {
    userData: Client;
  };
}

interface CreateClientRequest {
  _id: string;
  role: string;
  mobileNumber: string;
  clientTeam: string;
}

interface IGetClientsResponse {
  docs: Client[];
  totalDocs: number;
}

export const getClients = async (
  client: IGetClientRequest
): Promise<IGetClientsResponse> => {
  const response: Response<{ message: { userData: IGetClientsResponse } }> =
    await axios.get(`${WYSPR_WEBAPI_HOST}/users`, {
      params: {
        accountType: client.accountType,
        accountState: client.accountState,
        showDetail: client.showDetail,
        showTeam: client.showTeam,
        showWallet: client.showWallet,
        count: client.count,
        page: client.page,
        limit: client.limit,
        sortBy: client.sortBy,
        order: client.order
      }
    });
  return response.data.message.userData;
};

export const updateClient = async (
  client: UpdateClientRequest
): Promise<Client> => {
  const clientId = client._id;
  delete client._id;
  const response: Response<ClientResponse> = await axios.put(
    `${WYSPR_WEBAPI_HOST}/clients/${clientId}`,
    { ...client }
  );
  return response.data.message.clientData;
};

export const accountStatusUpdate = async (
  client: IUpdateAccountStatusRequest
): Promise<Client | Array<Client>> => {
  const response: Response<UserResponse> = await axios.put(
    `${WYSPR_WEBAPI_HOST}/users/account/review`,
    { ...client }
  );
  return response.data.message.userData;
};

export const createClient = async (
  client: CreateClientRequest
): Promise<Client> => {
  const response: Response<ClientResponse> = await axios.post(
    `${WYSPR_WEBAPI_HOST}/clients/${client._id}`,
    {
      ...client
    }
  );
  return response.data.message.clientData;
};

export const getRoles = async (): Promise<Role[]> => {
  const noAuthAxios = axios.create(); // Axios instance with no authentication added
  const response: Response<{ roles: Role[] }> = await noAuthAxios.get(
    `${WYSPR_WEBAPI_HOST}/clients/roles`
  );

  const arr = response.data.roles.map((role) => {
    return (role = {
      ...role,
      _id: randomId()
    });
  });

  return arr;
};
