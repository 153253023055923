import axios from 'axios';
import { WYSPR_WEBAPI_HOST, Response } from '../index';
import { User } from '../../models/user';
import { SizeOrganisation } from '../../models/user';
import randomId from 'src/utils/randomId';
import { UserResponse } from '../friendvertiser';

export enum AccountValidationEnum {
    ACCEPTED = "ACCEPTED",
    DECLINED = "DECLINED",
    PENDING = "PENDING"
}

export enum AccountTypeEnum {
    FRIENDVERTISER = "FRIENDVERTISER",
    CLIENT = "CLIENT",
    WYSPR = "WYSPR",
    UNDEFINED = "UNDEFINED"
}

export enum ClientSortingEnum { // In users because it is a getUser endpoint.
    ORGANIZATION = "ORGANIZATION",
    NAME = "NAME",
    EMAIL = "EMAIL",
    ROLE = "ROLE",
    SIZE = "SIZE",
    BUDGET = "BUDGET",
    WALLET = "WALLET",
    MARGIN = "MARGIN",
    CREATED = "CREATED",
    UPDATE = "UPDATED",
    STATUS = "STATUS"
}

export enum UserSortedByEnum {
    ASC = "ASC",
    DESC = "DESC"
}

export interface CreateUserRequest {
    _id: string;
    mobileNumber: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    clientTeam: {
        _id?: string
        name?: string
    }
}

export interface UpdateUserRequest {
    _id: string;
    mobileNumber: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    role: string;
    clientTeam?: string;
    avatarUrl?: {
        fileName: string;
        image96x96?: string;
        image375x375?: string;
    }
}

export const createUser = async (newUser: CreateUserRequest): Promise<User> => {
    const response: Response<User> = await axios.post(`${WYSPR_WEBAPI_HOST}/clients/account/create`, newUser);
    return response.data;
};

export const getUser = async (
    userId: string,
    showAvatars: boolean = false,
    showAudienceScreenshots: boolean = false
): Promise<User> => {
    const response: Response<User> = await axios.get(
        `${WYSPR_WEBAPI_HOST}/users/${userId}?showUserInfo=True&showAvatars=${showAvatars}&showAudienceScreenshots=${showAudienceScreenshots}`,
    );
    return response.data.message.userData;
};

export const getUserWithToken = async (userId: string, token: string, showAvatars: boolean = false): Promise<User> => {
    const noAuthAxios = axios.create(); // Axios instance with no authentication added

    const response: Response<User> = await noAuthAxios.get(
        `${WYSPR_WEBAPI_HOST}/users/${userId}?showUserInfo=True&showAvatars=${showAvatars}`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
    return response.data.message.userData;
};

export const updateUser = async (user: Partial<UpdateUserRequest>): Promise<User> => {
    const userId = user._id;
    delete user._id;
    const client: Response<User> = await axios.put(`${WYSPR_WEBAPI_HOST}/users/${userId}`,
        { ...user });

    return client.data;
};

export const getSizeOrganisation = async (): Promise<SizeOrganisation[]> => {
    const noAuthAxios = axios.create(); // Axios instance with no authentication added
    const response: Response<{ roles: SizeOrganisation[] }> = await noAuthAxios.get(
        `${WYSPR_WEBAPI_HOST}/client-team/size`,
    );
    const arr = response.data.roles.map((size) => {
        return size = {
            ...size,
            _id: randomId()
        }
    });

    return arr;
};

export const getAllUsers = async (): Promise<any> => {
    const response: Response<UserResponse> = await axios.get(
        `${WYSPR_WEBAPI_HOST}/users?accountType=ALL&accountState=ALL`,
    );
    return response.data.message.userData;
};

export const createFriendvertisersPromise = async (potentialCreators: string[]): Promise<string> => {
    const response: Response<UserResponse> = await axios.post(
        `${WYSPR_WEBAPI_HOST}/users`, potentialCreators
    );
    return response.data.message as unknown as string;
};

export const getUserContract = async (userId: string): Promise<string> => {
    const response: Response<{
        message: {
            file: string
        }
    }> = await axios.get(
        `${WYSPR_WEBAPI_HOST}/users/contract/${userId}`,
    );
    return response.data.message.file;
};
